import React from "react"
import Layout from "../components/layout/layout";
import Hero from "../components/hero/hero";
import {graphql} from "gatsby";
import Seo from "../components/seo/seo";
import BrandCard from "../components/brand/brandCard";

const Index = ({ data, location }) => {
  return (
    <Layout title="PINTALABIOS Y LABIALES QUE TE AYUDARÁN A TENER TUS LABIOS PERFECTOS" location={location}>
      <Seo title="Todo sobre pintalabios y labiales" description={data.site.siteMetadata.description} />
      <Hero
        breadcrumb={false}
        img={data.logo}
        title="Nueva definición de pintalabios y labiales"
        description="Hablar de pintalabios era hablar de algo muy genérico. Ahora, cada labial viene acompañado de palabras que lo definen a la perfección y que matizan su uso con miles de tonos disponibles. No todos pintan los labios, si no que algunos le puede añadir brillo o textura solamente o incluso hidratación (y no es un producto farmacológico). El mundo de los cosméticos ha ampliado su abanico de posibilidades y por ello ha tenido que dejar atrás este término que tan poco se adecuaba a los tiempos modernos."/>
      <div className="ph6-l ph5-m ph2">
        <h2 className="pa2 display dark-gray f3 tc mb3 h3">Marcas de labiales</h2>
        <div className="cf pa2">
          {data.brands.nodes.map((brand, key) =>  <BrandCard key={key} brand={brand}/>)}
        </div>
        <h2 className="pa2 display dark-gray f3 tc mb3 h3">Categorías de labiales</h2>
        <div className="cf sans-serif">
          <p>
            Actualmente, podemos categorizar a los labiales en {data.categories.nodes.length} categorías dependiendo de su función y de la forma en la que se aplican:
          </p>
            {data.categories.nodes.map((node, key) =>
              <dl className="lh-title" key={key}>
              <dt className="dib b">{node.title}:</dt>
              <dd className="dib ml0">{node.sentence}</dd>
              </dl>
            )}
        </div>
      </div>
    </Layout>
  )
}

export default Index;

export const query = graphql`
    query {
        categories: allCategories {
            nodes {
                title
                sentence
            }
        }
        brands: allBrands {
            nodes {
                slug
                title
                localBrandIconImg {
                    childImageSharp {
                        fixed(width: 120, height: 120) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
        logo: file(relativePath: { eq: "hero.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logoSite: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
                fixed(width: 1200, height: 300) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        site {
            siteMetadata {
                description
            }
        }
    }
`
