import React from "react"
import Img from "gatsby-image";
import {Link} from "gatsby";

const BrandCard = ({brand}) => {
  const { title, slug, localBrandIconImg } = brand;
  return (
    <div className="fl w-50 w-25-m w-20-l pa2">
      <Link to={slug} className="db link dim tc">
        <Img fixed={localBrandIconImg.childImageSharp.fixed} className="w-100 db outline black-10" alt={title+' Icon Image'} />
        <dl className="mt2 f6 lh-copy">
          <dt className="clip">Marca</dt>
          <dd className="ml0 black truncate w-100">{title}</dd>
        </dl>
      </Link>
    </div>
  )
};

export default BrandCard;
